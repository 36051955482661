import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const membershipsApi = createApi({
  reducerPath: 'memberships',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Memberships'],
  endpoints: (builder) => ({
    getAllMemberships: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/memberships?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['Memberships'],
    }),

    getMembershipsById: builder.query({
      query: (id) => `/memberships/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['Memberships'],
    }),

    // getServicesForDropDown: builder.query({
    //   query: () => `service/all`,
    //   transformResponse: (res) => res.body,
    // }),

    addNewMemberships: builder.mutation({
      query: (data) => ({
        url: `/memberships`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Memberships'],
    }),

    // getServicebySearch: builder.query({
    //   query: () => `/service/all`,
    //   transformResponse: (res) => res.body,
    // }),

    updateMemberships: builder.mutation({
      query: ({ _id, updateData }) => ({
        url: `/memberships/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updateData,
      }),
      invalidatesTags: ['Memberships'],
    }),

    // updateService: builder.mutation({
    //   query: ({ _id, updatedData }) => ({
    //     url: `/services/${_id}`,
    //     method: 'Put',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: updatedData,
    //   }),
    //   invalidatesTags: ['Services'],
    // }),
  }),
});

export const { useGetAllMembershipsQuery, useAddNewMembershipsMutation, useUpdateMembershipsMutation, useGetMembershipsByIdQuery } = membershipsApi;
