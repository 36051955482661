import PropTypes from 'prop-types';
// @mui
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from 'src/routes/components';
// components
import Iconify from 'src/components/iconify';
//
import { ListItem } from './styles';
import { useLocales } from 'src/locales';
import { smoothScrollTo } from "src/components/smooth-scroll";
import { useBoolean } from 'src/hooks/use-boolean';
// ----------------------------------------------------------------------

export default function NavItem({ item, onClose, open, active, onClick, externalLink, ...other }) {
  const { title, path, icon, children } = item;


  const { t } = useLocales();

  const renderContent = (
    <ListItem
      onClick={(e) => {
        e.preventDefault();
        smoothScrollTo(title);
        onClose()
      }}
      active={active === title} {...other}>
      <ListItemIcon> {icon} </ListItemIcon>

      <ListItemText disableTypography primary={t(title)} />

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1 }}
        />
      )}
    </ListItem>
  );

  // External link
  if (externalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} href={path} underline="none">
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};
