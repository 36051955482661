import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const giftcardsApi = createApi({
  reducerPath: 'giftcards',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['GiftCards'],
  endpoints: (builder) => ({
    getAllGiftCards: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/gift-cards?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['GiftCards'],
    }),

    addNewGiftCards: builder.mutation({
      query: (data) => ({
        url: `/gift-cards`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['GiftCards'],
    }),

    updateGiftCards: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/gift-cards/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['GiftCards'],
    }),
  }),
});

export const { useGetAllGiftCardsQuery, useAddNewGiftCardsMutation, useUpdateGiftCardsMutation } = giftcardsApi;
