import React, { useEffect } from 'react'
import AboutHero from '../about-hero'
import AboutWhat from '../about-what'
import { useGetTacQuery } from 'src/store/Reducer/settings'
import { useSettingsContext } from 'src/components/settings';

export default function TACView() {
  const { data } = useGetTacQuery();

  return (
    <>
      <AboutHero data={{ title: "Our", list: ['Terms And Conditions'] }} />
      <AboutWhat data={{ title: "", content: data?.body?.tac, image: '/assets/images/about/Terms_and_conditions.png' }} />
    </>
  )
}
