import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const businessApi = createApi({
  reducerPath: 'business',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Business', 'Vacations'],
  endpoints: (builder) => ({
    getAllBusiness: builder.query({
      query: ({ search, pageno, limit }) =>
        `/business?search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['Business'],
    }),

    getBusinessById: builder.query({
      query: (id) => `/business/${id}`,
      transformResponse: (res) => res.body,
      providesTags: ['Vacations'],
    }),

    updateBusinessHours: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/business/${_id}/opening-hours`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Vacations'],
    }),

    updateBusinessVacations: builder.mutation({
      query: ({ _id, vacations }) => (
        console.log("Payload = ", vacations), {
          url: `/business/${_id}/vacations`,
          method: 'Put',
          headers: { 'Content-Type': 'application/json' },
          body: vacations,
        }),
      invalidatesTags: ['Vacations'],
    }),

  }),
});

export const { useGetAllBusinessQuery, useGetBusinessByIdQuery, useUpdateBusinessHoursMutation, useUpdateBusinessVacationsMutation } = businessApi;
