import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import { MotionContainer, varFade } from 'src/components/animate';
import { bgGradient } from 'src/theme/css';
import { alpha, useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

export default function AboutHero({ data }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.grey[900], 0.8),
        }),
        height: { md: 300 },
        py: { xs: 10, md: 0 },
        borderRadius: '.7rem .7rem .7rem .7rem',
        overflow: 'hidden',
        position: 'relative',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage:
          'url(/assets/background/overlay_1.svg), url(/assets/images/about/hero.jpg)',
      }}
    >
      <Container sx={{ textAlign: 'center' }} component={MotionContainer}>
        <Box
          sx={{
            bottom: { md: 20 },

          }}
        >
          <TextAnimate text={data.title} variants={varFade().inRight} sx={{ color: 'primary.main', marginRight: '1rem' }} />

          <Stack spacing={2} display="inline-flex" direction="row" sx={{ color: 'common.white' }}>
            {data.list ? (
              data.list.map((v, index) => <TextAnimate key={index} text={v} />)
            ) : (
              <>
                <TextAnimate text="We" />
                <TextAnimate text="are?" />
              </>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

// ----------------------------------------------------------------------

function TextAnimate({ text, variants, sx, ...other }) {

  const { t } = useLocales();

  console.log(text);
  
  console.log(t(text))
  return (
    <Box
      component={m.div}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      <m.span variants={variants || varFade().inUp}>
        {t(text)}
      </m.span>

    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  variants: PropTypes.object,
};
// AboutHero.propTypes = {
//   text: PropTypes.arrayOf(PropTypes.string),
// };
