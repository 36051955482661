import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// console.log(process.env.REACT_APP_HOST_API, 'kdkl');
// Define a function that returns a configured fetchBaseQuery with token implementation
export const createCustomFetchBaseQuery = () => {
  // Define the base query
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HOST_API,
    prepareHeaders: (headers, { getState }) => {
      const user = getState()?.user?.user;
      const token = user ? user.accesstoken : '';

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

  // Return the custom base query with additional 401 handling logic
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      sessionStorage.clear()
      localStorage.clear()
    }

    return result;
  };
};

