// redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user', // The name of the slice
  initialState: { user: null, branch : null }, // The initial state of the slice
  reducers: {

    setUser: (state, action) => {
      let user = { ...action.payload };
      user.displayName = `${user?.name}`
      state.user = user;
    }, // Action to set the user in the state
    logout: (state) => {
      localStorage.clear();
      state.user = null
    }, // Action to clear the user from the state

    setBranch: (state, action) => {
      let branch = { ...action.payload };
      state.branch = branch
    },

    removeBranch: (state) => {
      localStorage.clear();
      state.branch = null
    },
  },
});

export const { setUser, logout, setBranch, removeBranch } = userSlice.actions;
export const selectUser = (state) => state.user.user;

export const selectBranch = (state) => state.user.branch;

export default userSlice.reducer;
