// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------


export const admin_paths = '/admin/dasboard'
export const partner_paths = '/partner/dashboard'
export const HOST_API = "https://Timezzi.co.za.";
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/d ashboard'

export const PATH_AFTER_PARTNER_LOGIN = paths.partner.root; // as '/partherDashboard'

// ROOT PATH AFTER SUPER-ADMIN LOGIN SUCCESSFUL
export const PATH_AFTER_SUPERADMIN_LOGIN = paths.superAdminDashboard.root;

// ROOT PATH AFTER USER LOGIN SUCCESSFUL
export const PATH_AFTER_ADMIN_LOGIN = paths.adminDashboard.root;
export const FORGOT_PASSWORD = paths.forgotPassword;
export const NEW_PASSWORD = paths.newPassword;
export const VERIFY_PASSWORD = paths.verify;
export const CREATE_ACCOUNT = paths.register;
