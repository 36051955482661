import React from 'react'
import AboutHero from '../about-hero'
import AboutWhat from '../about-what'
import { useGetPrivacyQuery } from 'src/store/Reducer/settings'
import AboutWhatWeOffer from '../what-we-offer-data';

export default function WhatWeOfferView() {
  const { data } = useGetPrivacyQuery();
  return (
    <>
      <AboutHero data={{ title: "What", list: ['We Offer'] }} />
      <AboutWhatWeOffer data={{ title: "", content: data?.body?.privacy, image: '/assets/images/about/Privacy_Policy.png' }} />
    </>
  )
}

