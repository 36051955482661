import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

import foxnoxLogo from './foxnoxLogo.png';
import { useMockedUser } from 'src/hooks/use-mocked-user';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const { user } = useMockedUser();

  const logo = (
    <Box
      ref={ref}
      component="div"
      py={1}
      sx={{
        width: 150,
        height: 80,
        display: 'inline-flex',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <img src={foxnoxLogo} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <>
      {user?.email ? (
        <Link component={RouterLink} sx={{ display: 'contents' }}>
          {logo}
        </Link>
      ) : (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          {logo}
        </Link>
      )}
    </>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
