import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useSelector } from 'react-redux';

//
import { useAuthContext } from '../hooks';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { admin_paths, partner_paths } from 'src/config-global';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const loginPaths = {
  jwt: paths.login,
  auth0: paths.auth.auth0.login,
  amplify: paths.auth.amplify.login,
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const { user } = useMockedUser();
  const { method } = useAuthContext();

  const [checked, setChecked] = useState(false);

  // const redirectpath = useCallback(() => {
  //   if (user?.user_type === 2) {
  //     return partner_paths;
  //   } else {
  //     return admin_paths;
  //   }
  // }, [user]);

  const location = useLocation();

  
  const redirectpath = useCallback(() => {
    if (user?.user_type === 0 && location.pathname.includes('/partner')) {
      return -1;
    } else if (user?.user_type === 2 && location.pathname.includes('/admin')) {
      return -1;
    } else {
      return router.push(`${location.pathname}`);
    }
  }, [user]);

  const check = useCallback(() => {
    if (!user?.email) {
      const loginPath = loginPaths[method];
      const href = `${loginPath}`;
      router.replace(href);
    } else {
      router.push(redirectpath());
      setChecked(true);
    }
  }, [user, method, router]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
