import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const equipmentApi = createApi({
  reducerPath: "equipment",
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Equipment'],
  endpoints: (builder) => ({
    getAllEquipment: builder.query({   
      query: ({ pageno, limit, search }) => `/equipment/user?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['Equipment'],
    }),

    addNewEquipment: builder.mutation({
      query: (newEquipment) => ({
        url: `/equipment`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newEquipment,
      }),
      invalidatesTags: ['Equipment'],
    }),

    updateEquipment: builder.mutation({
      query: (data) => (console.log("On API : ", data),
      {
          url: `/equipment/${data.id}`,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        }
      ),
      invalidatesTags: ['Equipment'],
    }),

  }),
});

export const {
  useGetAllEquipmentQuery,
  useAddNewEquipmentMutation,
  useUpdateEquipmentMutation,
} = equipmentApi;
