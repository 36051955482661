import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const discountsApi = createApi({
  reducerPath: 'discounts',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Discounts'],
  endpoints: (builder) => ({
    getAllDiscounts: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/discounts?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providesTags: ['Discounts'],
    }),

    addNewDiscount: builder.mutation({
      query: (data) => ({
        url: `/discounts`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Discounts'],
    }),

    updateDiscount: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/discounts/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Discounts'],
    }),
  }),
});

export const { useGetAllDiscountsQuery, useAddNewDiscountMutation, useUpdateDiscountMutation } =
  discountsApi;
