import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const categoryApi = createApi({
  reducerPath: 'category',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Category'],
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: () => `/categories`,
      transformResponse: (res) => res.body,
      providesTags: ['Category'],
    }),

    getCategoryByID: builder.query({
      query: (id) => `/categories/${id}`,
      transformResponse: (res) => res.body,
      providesTags: ['Category'],
    }),

    getCategoriesDropdown: builder.query({
      query: () => `/service/categories/user`,
      transformResponse: (res) => res.body,
    }),


    

    addNewCategory: builder.mutation({
      query: (newCategory) => ({
        url: `/categories`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newCategory,
      }),
      invalidatesTags: ['Category'],
    }),

    updateCategory: builder.mutation({
      query: (data) => ({
        url: `/categories/${data.id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data.updatedData,
      }),
      invalidatesTags: ['Category'],
    }),
    deleteCategory: builder.mutation({
      query: (data) => ({
        url: `/categories/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useGetCategoriesDropdownQuery,
  useAddNewCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryByIDQuery,
  useDeleteCategoryMutation,
} = categoryApi;
