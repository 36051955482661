import { _mock } from 'src/_mock';
import { useSelector } from 'react-redux';
import { selectBranch } from 'src/store/slices/userSlice';


export function useMockedBranch() {
  // Use the useSelector hook to select the user from the Redux store
  const branch = useSelector(selectBranch);

  // Return the user fetched from the Redux store
  return { branch };
}
