import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// components
import Iconify from 'src/components/iconify';
import { IconButton, Tooltip, useTheme } from '@mui/material';

import { useDeleteCategoryMutation } from 'src/store/Reducer/category';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
import { format } from 'date-fns';
import { fontSize } from '@mui/system';
// ----------------------------------------------------------------------

export default function DisplayBusinessSubcategories({ subcategories }) {
  const _mockFollowed = subcategories?.slice(4, 8).map((i) => i.id);

  const [followed, setFollowed] = useState(_mockFollowed);

  const handleClick = useCallback(
    (item) => {
      const selected = followed.includes(item)
        ? followed.filter((value) => value !== item)
        : [...followed, item];

      setFollowed(selected);
    },
    [followed]
  );

  return (
    <>
      <Box
        sx={{ margin: '0rem' }}
        gap={1}
        display="flex"
        flexWrap={'wrap'}
      >
        {subcategories?.map((iter) => (
          <SubCategoryItem
            key={iter.id}
            subcategory={iter}
          />
        ))}
      </Box>
    </>
  );
}

DisplayBusinessSubcategories.propTypes = {
  subcategories: PropTypes.array,
};

// ----------------------------------------------------------------------

function SubCategoryItem({ subcategory, selected, onSelected }) {
  const { name, image, createdAt } = subcategory;
  const [quicksubEdit, setQuickSubEdit] = useState(false);
  const enqueueSnackbar = useSnackbar();

  const theme = useTheme();
  const [deleteCategory] = useDeleteCategoryMutation();
  const handleDelete = async (data) => {
    try {
      const response = await deleteCategory({ id: subcategory?._id });

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        enqueueSnackbar('Deleted Successfully!');
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: (theme) => theme.spacing(3, 2, 3, 3),
        width: '22rem',
      }}
    >
      <Avatar alt={name} src={image} sx={{ width: 48, height: 48, mr: 2 }} />

      <ListItemText
        primary={
          <Tooltip title={name} placement="top">
            {name}
          </Tooltip>
        }
        // secondary={
        //   <Button
        //     size="small"
        //     variant={selected ? 'text' : 'outlined'}
        //     color={selected ? 'success' : 'error'}
        //     startIcon={selected ? <Iconify width={18} icon="eva:checkmark-fill" /> : null}
        //     onClick={onSelected}
        //     sx={{ flexShrink: 0 }}
        //   >
        //     {selected ? 'Active' : 'Inactive'}
        //   </Button>
        // }
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle2',
        }}
        // secondaryTypographyProps={{
        //   mt: 0.5,
        //   noWrap: true,
        //   display: 'flex',
        //   component: 'span',
        //   alignItems: 'center',
        //   typography: 'caption',
        //   color: 'text.disabled',
        // }}
      />

      <Box sx={{ marginLeft: '1.5rem' }}>
        <ListItemText
          primary={format(new Date(createdAt), 'dd MMM yyyy')}
          secondary={'Created At'}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'subtitle2',
            // color: theme.palette.primary.main,
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            noWrap: true,
            display: 'flex',
            component: 'span',
            alignItems: 'center',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      </Box>
    </Card>
  );
}

SubCategoryItem.propTypes = {
  subcategory: PropTypes.object,
  onSelected: PropTypes.func,
  selected: PropTypes.bool,
};
