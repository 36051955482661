import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { Card, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadAvatar,
} from 'src/components/hook-form';

import Switch from '@mui/material/Switch';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiColorInput } from 'mui-color-input';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useState } from 'react';
import Label from 'src/components/label';

const BusinessTimingSlots = ({ index, data, onFilters }) => {
  const [disabledTime, setDisabledTime] = useState(data.isOpen);

  const handleFilterSwitch = useCallback(
    (event) => {
      setDisabledTime(event.target.checked);
      onFilters('isOpen', event.target.checked, index);
    },
    [onFilters]
  );

  const handleStartTime = useCallback((event) => {
    try {
      var startTime = format(new Date(event.$d), 'HH:mm');
      onFilters('startTime', startTime, index);
    } catch (error) {
      console.log(error)
    }
  });

  const handleEndTime = useCallback((event) => {
    try {
      var endTime = format(new Date(event.$d), 'HH:mm');
      onFilters('endTime', endTime, index);
    } catch (error) {
      console.log(error)
    }


  });

  return (
    <Card borderRadius={'.5rem'} sx={{ maxWidth: '30rem', minWidth: '20rem' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '.5rem',
          p: 0.5,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Iconify icon="solar:clock-square-bold-duotone" sx={{ marginRight: '.5rem' }} />
          <Label color={'primary'} sx={{ fontSize: '.9rem' }}>{data.day} </Label>
        </Typography>
        <FormControlLabel
          sx={{ marginRight: '-5px' }}
          control={
            <Switch onChange={(event) => handleFilterSwitch(event)} defaultChecked={data.isOpen} />
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          p: 0.5,
          marginBottom: 0.5,
        }}
      >
        <div style={{ marginRight: '.2rem' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="Start Time">
              <MobileTimePicker
                onChange={handleStartTime}
                defaultValue={dayjs(`0000-00-00T${data.startTime}`)}
                disabled={!disabledTime}
              />
            </DemoItem>
          </LocalizationProvider>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="End Time">
              <MobileTimePicker
                onChange={handleEndTime}
                defaultValue={dayjs(`0000-00-00T${data.endTime}`)}
                disabled={!disabledTime}
              />
            </DemoItem>
          </LocalizationProvider>
        </div>
      </Box>
    </Card>
  );
};

export default BusinessTimingSlots;
