import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhatWeOffer({ data }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const settings = useSettingsContext();

  const { t, currentLang } = useLocales();

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
        <Grid xs={12} md={12} lg={12}>
          {/* FIRST BOX */}
          <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
            For Customers:
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            1. Social Connectivity:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Status Updates:</strong> Share your plans for the night and see what your
                  friends are up to.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Stories:</strong> Post and view stories from friends and local venues to
                  stay connected and informed.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* SECOND BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            2. Event Discovery:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Event Listings:</strong> Browse and RSVP to a variety of events happening
                  around you.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Exclusive Invitations:</strong> Receive personalized invites to private
                  and public events.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* THIRD BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            3. Special Deals:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Fast Passes and Covers:</strong> Purchase fast passes and prepaid covers
                  for quicker entry to popular spots.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Exclusive Offers:</strong> Access special promotions and discounts from
                  local bars and venues.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* FORTH BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            4. Group Management:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Greek Life Integration:</strong> Manage and join Greek life groups, create
                  events, and send invitations.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Chat and Collaboration:</strong> Communicate with group members through
                  integrated chat features.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* FIFTH BOX */}
          <Typography variant="h4" sx={{ mb: 1, mt: 3 }}>
            For Businesses:
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            1. Event Promotion:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Create Events:</strong> Easily create and manage events to attract more
                  customers.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Targeted Invitations:</strong> Send personalized invitations to your
                  target audience.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* SIXTH BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            2. Customer Engagement:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Post Updates:</strong> Share stories and updates to keep your followers
                  informed and engaged.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Special Deals:</strong> Offer exclusive promotions and discounts to
                  attract more patrons.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* SEVEN BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            3. Analytics and Insights:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Performance Metrics:</strong> Access detailed analytics on event
                  attendance, deal redemptions, and customer engagement.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Customer Feedback:</strong> Gather feedback from customers to improve your
                  services and offerings.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          {/* EIGHTH BOX */}
          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            4. Streamlined Entry:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Fast Pass Integration:</strong> Allow customers to purchase fast passes
                  for quicker entry to your venue.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Cover Charge Management:</strong> Simplify cover charge transactions with
                  integrated prepaid options.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
