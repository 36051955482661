import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const paymentsApi = createApi({
  reducerPath: 'payments',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Payments'],
  endpoints: (builder) => ({
    getAllPayments: builder.query({
      query: ({search, pageno, limit, branch}) =>
        `/payments?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providedTags: ['Payments'],
    }),
  }),
});

export const { useGetAllPaymentsQuery } = paymentsApi;
