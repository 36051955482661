import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
// _mock
import { _socials } from 'src/_mock';
import Typography from '@mui/material/Typography';
import ResponsiveDatePickers from './date-picker';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import BusinessTimingSlots from './business-timing-slots';
import { useState } from 'react';
import { useCallback } from 'react';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { LoadingButton } from '@mui/lab';


import { useGetBusinessByIdQuery, useUpdateBusinessHoursMutation } from 'src/store/Reducer/business';
// ----------------------------------------------------------------------

export default function BusinessVacations({ info, posts }) {

  const { branch } = useMockedBranch();

  const { data: BusinessData, status } = useGetBusinessByIdQuery(branch._id);

  const { enqueueSnackbar } = useSnackbar();

  const [updateBusinessHours] = useUpdateBusinessHoursMutation();

  const [openingHoursArray, setOpeningHoursArray] = useState(branch?.openingHours);

  const confirm = useBoolean();

  const handleFilters = useCallback((name, value, index) => {
    setOpeningHoursArray((prevState) => {
      const newState = [...prevState];

      newState[index] = {
        ...newState[index],
        [name]: value,
      };

      return newState;
    });
  }, []);


  const handleSubmit = (async (data) => {
    try {
      const newData = {
        openingHours: openingHoursArray,
      };
      
      let response = await updateBusinessHours({
        _id: branch?._id,
        updatedData: newData,
      });

      console.log("Response = ", response)


      if (response.error) {
        enqueueSnackbar(response.error.data.message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } else {
        enqueueSnackbar('Update Success!');
        await new Promise((resolve) => setTimeout(resolve, 500));
        confirm.onFalse();
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={12} sx={{ p: (theme) => theme.spacing(2, 2, 3, 3) }}>

        <div style={{
          marginBottom: '1.5rem',
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <Typography margin={'.7rem 0 0 .1rem '} variant="h4" fontWeight={800}>
            Manage Business Vacations
          </Typography>
        </div>
        <ResponsiveDatePickers />
      </Grid>
      <Divider sx={{ borderWidth: '.1rem', color: 'black', width: '100%', borderStyle: 'dashed' }} />
      <Grid xs={12} md={12} sx={{ p: (theme) => theme.spacing(2, 2, 3, 3) }}>
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <Typography margin={'.7rem 0 0 .1rem '} variant="h4" fontWeight={800}>
            Manage Business Hours
          </Typography>
        </div>



        <Box
          marginTop={2}
          rowGap={3}
          columnGap={2}

          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
        >
          {BusinessData?.openingHours?.map((iter, index) => {
            return (
              <BusinessTimingSlots
                key={iter._id}
                onFilters={handleFilters}
                index={index}
                data={iter}
              />
            );
          })}
        </Box>

        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          < Button onClick={confirm.onTrue} sx={{ mt: 2 }} variant="contained" size="large">
            Save Business Hours
          </Button>
        </div>
      </Grid >

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Confirm"
        content="Are you sure want to save?"
        action={
          <LoadingButton onClick={handleSubmit} type="submit" variant="contained" color='primary'>
            Save
          </LoadingButton>
        }
      />
    </Grid >
  );
}

BusinessVacations.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
