import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/english.json';
import translationAr from './langs/arabic.json';
import translationUr from './langs/urdu.json';
import translationZh from './langs/chinese.json';
import translationBn from './langs/bengali.json';
import translationDe from './langs/german.json';
import translationDa from './langs/danish.json';
import translationFi from './langs/finnish.json';
import translationFr from './langs/french.json';
import translationGa from './langs/irish.json';
import translationKu from './langs/kurdish.json';
import translationMs from './langs/malay.json';
import translationMzh from './langs/mandarin.json';
import translationNl from './langs/dutch.json';
import translationNo from './langs/norwegian.json';
import translationSv from './langs/swedish.json';
import translationPl from './langs/polish.json';
import translationPt from './langs/portuguese.json';
import translationRu from './langs/russian.json';
import translationHi from './langs/hindi.json';
import translationTa from './langs/tamil.json';
import translationTh from './langs/thai.json';
import translationTr from './langs/turkish.json';
import translationHy from './langs/armanian.json';
import translationPa from './langs/punjabi.json';
import translationEs from './langs/spanish.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      hi: { translations: translationHi },
      hy: { translations: translationHy },
      pa: { translations: translationPa },
      ta: { translations: translationTa },
      tr: { translations: translationTr },
      th: { translations: translationTh },
      ar: { translations: translationAr },
      ur: { translations: translationUr },
      zh: { translations: translationZh },
      de: { translations: translationDe },
      bn: { translations: translationBn },
      fr: { translations: translationFr },
      fi: { translations: translationFi },
      da: { translations: translationDa },
      ga: { translations: translationGa },
      ku: { translations: translationKu },
      ms: { translations: translationMs },
      mzh: { translations: translationMzh },
      nl: { translations: translationNl },
      no: { translations: translationNo },
      sv: { translations: translationSv },
      pl: { translations: translationPl },
      pt: { translations: translationPt },
      ru: { translations: translationRu },
      es: { translations: translationEs },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
