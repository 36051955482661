import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptions',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Subscriptions'],
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query({
      query: ({ search, pageno, limit }) =>
        `/subscription/all?search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => ({ total_length: res.body.total_length, data: res.body.data }),
      providesTags: ['Subscriptions'],
    }),

    // getServicesForDropDown: builder.query({
    //   query: () => `service/all`,
    //   transformResponse: (res) => res.body,
    // }),

    // addNewService: builder.mutation({
    //   query: (newService) => ({
    //     url: `/services`,
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: newService,
    //   }),
    //   invalidatesTags: ['Services'],
    // }),

    // getServicebySearch: builder.query({
    //   query: () => `/service/all`,
    //   transformResponse: (res) => res.body,
    // }),


    // updateOfferType: builder.mutation({
    //   query: ({ _id, updatedData }) => ({
    //     url: `/services/${_id}/offer`,
    //     method: 'Put',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: updatedData,
    //   }),
    //   invalidatesTags: ['Services'],
    // }),

    // updateService: builder.mutation({
    //   query: ({ _id, updatedData }) => ({
    //     url: `/services/${_id}`,
    //     method: 'Put',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: updatedData,
    //   }),
    //   invalidatesTags: ['Services'],
    // }),
  }),
});

export const {
  useGetAllSubscriptionsQuery,
} = subscriptionApi;
