import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhat({ data }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const settings = useSettingsContext();

  const { t, currentLang } = useLocales();

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
        {/* <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ p: { md: 3 } }}>
          <Grid xs={12}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src={data?.image}
                ratio="1/1"
                sx={{ borderRadius: 3 }}
              />
            </m.div>
          </Grid>
        </Grid> */}

        <Grid xs={12} md={12} lg={12}>
            <Typography  variant="h4" sx={{ mb: 1, mt: 2 }}>
              Privacy Policy
            </Typography>

          {/* <m.div variants={varFade().inRight}> */}

          <Typography
            variant="h4"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            Effective Date: October 1st 2024
          </Typography>

          <Typography
          mt={1}
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            Welcome to Fox Nox ("we," "our," "us"). Fox Nox is committed to protecting your privacy.
            This Privacy Policy explains how we collect, use, disclose, and safeguard your
            information when you use our mobile application (the "App"). Please read this Privacy
            Policy carefully. If you do not agree with the terms of this Privacy Policy, please do
            not access the App.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            1. Information We Collect
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            1.1 Personal Information
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            We may collect personal information that you voluntarily provide to us when you:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Register for an account</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Update your profile</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Use certain features</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Contact us for support</Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            Personal Information collected may include:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Name</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Email address</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Phone number</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Profile picture</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Date of birth</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Gender</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>School and graduation year</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Greek life group affiliation</Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            1.2 Usage Data
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            We may automatically collect usage data when you access and use the App. This
            information may include:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Device information (e.g., device type, operating system)</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>IP address</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Browser type and version</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Pages you visit within the App</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Time and date of your visit</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Time spent on those pages</Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            1.3 Location Information
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            With your consent, we may collect information about your location. You can enable or
            disable location services at any time through your mobile device settings.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            2. How We Use Your Information
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            We use the information we collect to:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Create and manage your account</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Provide and improve our services</Typography>
              </Box>
            </Stack>
            {/* style={{ minWidth: '0.85rem' }} */}
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Personalize your experience</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Send administrative information, such as updates and security alerts
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Respond to your comments, questions, and requests</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Monitor and analyze usage and trends to improve your experience
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Send marketing and promotional communications (with your consent)
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Detect and prevent fraud and illegal activities</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>Comply with legal obligations</Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            3. Sharing Your Information
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            We do not sell, trade, or transfer your personal information to outside parties, except
            in the following circumstances:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>With your consent</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  To trusted third parties who assist us in operating the App, conducting our
                  business, or providing services, as long as those parties agree to keep the
                  information confidential
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  To comply with legal requirements (e.g., law, court order, or legal process)
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />
              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  To protect our rights, property, or safety, or the rights, property, or safety of
                  others
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            4. Security of Your Information
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            We implement appropriate technical and organizational measures to protect your personal
            information from unauthorized access, use, disclosure, alteration, or destruction.
            However, no data transmission over the internet or electronic storage is 100% secure,
            and we cannot guarantee absolute security.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            5. Your Rights
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            You have the following rights regarding your personal information:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Access:</strong> Request access to the personal information we hold about
                  you.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Correction:</strong> Request correction or updates to your personal
                  information.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Deletion:</strong> Request the deletion of your personal information.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Objection:</strong> Object to the processing of your personal information.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Restriction:</strong> Request that we restrict the processing of your
                  personal information.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  <strong>Portability:</strong> Request a copy of your personal information in a
                  structured, commonly used, machine-readable format.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            To exercise any of these rights, please contact us at:
            <strong>Email:</strong> info@foxnoxsocial.com
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            6. Third-Party Links
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            The App may contain links to third-party websites or services that are not owned or
            controlled by us. We are not responsible for the privacy practices of these third-party
            websites or services. We encourage you to review their privacy policies before providing
            any information.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            7. Children's Privacy
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            Our App is not intended for use by children under the age of 18. We do not knowingly
            collect personal information from children under 18. If we become aware that we have
            collected such information, we will take steps to delete it from our records.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            8. Changes to This Privacy Policy
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            This Privacy Policy is current as of the Effective Date set forth above. We may update
            this Privacy Policy from time to time. If changes materially affect the collection or
            use of your personal information, we will provide notice by highlighting the changes on
            the App.
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            9. Contact Us
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            If you have any questions about this Privacy Policy or would like to make a complaint,
            please contact us at:
          </Typography>

          <Typography  variant="h5" sx={{ mb: 1, mt: 2 }}>
            Fox Nox, LLC
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'light' ? 'text.secondary' : 'common.white',
            }}
          >
            Phone: (918)-640-7959 <br />
            Email: info@foxnoxsocial.com
          </Typography>

          {/* </m.div> */}
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
