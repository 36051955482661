import merge from 'lodash/merge';
import {
  enUS as enUSAdapter,
  fr as frFRAdapter,
  vi as viVNAdapter,
  zhCN as zhCNAdapter,
  arSA as arSAAdapter,
  de as deDEAdapter,
  bn as bnBDAdapter,
  da as daDKAdapter, // Danish
  fi as fiFIAdapter, // Finnish
  ga as gaIEAdapter, // Irish
  ku as kuCKBAdapter, // Kurdish
  ms as msMSAdapter, // Malay
  ptBR as ptBRAdapter, // Portuguese (Brazilian)
  sv as svSEAdapter, // Swedish
  pl as plPLAdapter, // Polish
  ru as ruRUAdapter, // Russian
  ta as taINAdapter, // Tamil
  tr as trTRAdapter, // Turkish
  th as thTHAdapter, // Thai
  hi as hiINAdapter, // Hindi
  hy as hyAMAdapter, // Armenian
  pa as paINAdapter, // Punjabi
  es as esESAdapter, // Spanish

} from 'date-fns/locale';
// core
import {
  enUS as enUSCore, // English
  frFR as frFRCore, // French
  viVN as viVNCore,
  zhCN as zhCNCore, // Chinese
  arSA as arSACore, // Arabic
  deDE as deDECore, // German
  bnBD as bnBDCore, // Bengali
  daDK as daDKCore, // Danish
  fiFI as fiFICore, // Finnish
  gaIE as gaIECore, // Irish
  kuCKB as kuCKBCore, // Kurdish
  msMS as msMSCore, // Malay
  // zhCN as zhCNCore, // Mandarin (Chinese)
  nlNL as nlNLCore, // Dutch
  noNO as noNOCore, // Norwegian
  ptBR as ptBRCore, // Portuguese (Brazilian)
  svSE as svSECore, // Swedish
  plPL as plPLCore, // Polish
  ruRU as ruRUCore, // Russian
  taIN as taINCore, // Tamil
  trTR as trTRCore, // Turkish
  thTH as thTHCore, // Thai
  hiIN as hiINCore, // Hindi
  hyAM as hyAMCore, // Armenian
  paIN as paINCore, // Punjabi
  esES as esESCore, // Spanish

} from '@mui/material/locale';
// date-pickers
import {
  enUS as enUSDate,
  frFR as frFRDate,
  viVN as viVNDate,
  zhCN as zhCNDate,
  deDE as deDEDate,
  gaIE as gaIEDate, // Irish
  ptBR as ptBRDate, // Portuguese (Brazilian)
  taIN as taINDate, // Tamil
  trTR as trTRDate, // Turkish
  thTH as thTHDate, // Thai
  hiIN as hiINDate, // Hindi
  hyAM as hyAMDate, // Armenian
  esES as esESDate, // Spanish

  paIN as paINDate, // Punjabi
} from '@mui/x-date-pickers/locales';
// data-grid
import {
  enUS as enUSDataGrid,
  frFR as frFRDataGrid,
  viVN as viVNDataGrid,
  zhCN as zhCNDataGrid,
  arSD as arSDDataGrid,
  deDE as deDEDataGrid,
  gaIE as gaIEDataGrid, // Irish
  ptBR as ptBRDataGrid, // Portuguese (Brazilian)
  taIN as taINDataGrid, // Tamil
  trTR as trTRDataGrid, // Turkish
  thTH as thTHDataGrid, // Thai
  hiIN as hiINDataGrid, // Hindi
  hyAM as hyAMDataGrid, // Armenian
  paIN as paINDataGrid, // Punjabi
  esES as esESDataGrid, // Spanish

} from '@mui/x-data-grid';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
    subLabel: 'English'
  },
  {
    label: 'Arabic',
    value: 'ar',
    systemValue: merge(arSDDataGrid, arSACore),
    adapterLocale: arSAAdapter,
    icon: 'twemoji:flag-united-arab-emirates',
    subLabel: 'العربية'
  },
  {
    label: 'Urdu',
    value: 'ur',
    systemValue: merge(arSDDataGrid, arSACore), // Adjust as needed
    adapterLocale: arSAAdapter, // Use Arabic adapter if Urdu adapter isn't available
    icon: 'twemoji:flag-for-flag-pakistan', // Use Pakistan flag for Urdu
    subLabel: 'اردو'
  },
  {
    label: 'Tamil',
    value: 'ta',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'twemoji:flag-india', // Use India flag for Tamil
    subLabel: 'தமிழ்'
  },
  {
    label: 'Turkish',
    value: 'tr',
    systemValue: merge(trTRDate, trTRDataGrid, trTRCore),
    adapterLocale: trTRAdapter,
    icon: 'twemoji:flag-turkey', // Use Turkey flag for Turkish
    subLabel: 'Türkçe'
  },
  {
    label: 'Thai',
    value: 'th',
    systemValue: merge(thTHCore),
    adapterLocale: thTHAdapter,
    icon: 'twemoji:flag-thailand', // Use Thailand flag for Thai
    subLabel: 'ภาษาไทย'
  },
  {
    label: 'Hindi',
    value: 'hi',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: hiINAdapter,
    icon: 'twemoji:flag-india', // Use India flag for Hindi
    subLabel: 'हिंदी'
  },
  {
    label: 'Punjabi',
    value: 'pa',
    systemValue: merge(arSDDataGrid, arSACore),
    adapterLocale: arSAAdapter,
    icon: 'twemoji:flag-india', // Use India flag for Punjabi (since it's predominantly spoken in India and Pakistan)
    subLabel: 'پنجابی'
  },
  {
    label: 'Bengali',
    value: 'bn',
    systemValue: merge(bnBDCore), // Adjust as needed
    adapterLocale: bnBDAdapter, // Use Bengali adapter
    icon: 'twemoji:flag-bangladesh', // Use Bangladesh flag for Bengali
    subLabel: 'বাংলা'
  },
  {
    label: 'Chinese',
    value: 'zh',
    systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
    adapterLocale: zhCNAdapter,
    icon: 'twemoji:flag-china',
    subLabel: '中文'
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: merge(esESDate, esESDataGrid, esESCore),
    adapterLocale: esESAdapter,
    icon: 'twemoji:flag-spain', // Use Spain flag for Spanish
    subLabel : 'Español'
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDate, deDEDataGrid, deDECore),
    adapterLocale: deDEAdapter,
    icon: 'twemoji:flag-germany',
    subLabel: 'Deutsch'
  },
  {
    label: 'Danish',
    value: 'da',
    systemValue: merge(daDKCore), // Adjust as needed
    adapterLocale: daDKAdapter, // Use Danish adapter
    icon: 'twemoji:flag-denmark', // Use Denmark flag for Danish
    subLabel: 'Dansk'
  },
  {
    label: 'Finnish',
    value: 'fi',
    systemValue: merge(fiFICore), // Adjust as needed
    adapterLocale: fiFIAdapter, // Use Finnish adapter
    icon: 'twemoji:flag-finland', // Use Finland flag for Finnish
    subLabel: 'Suomi'
  },
  {
    label: 'Armenian',
    value: 'hy',
    systemValue: merge(hyAMCore),
    adapterLocale: hyAMAdapter,
    icon: 'twemoji:flag-armenia', // Use Armenia flag for Armenian
    subLabel: 'Հայերեն'
  },

  {
    label: 'French',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'twemoji:flag-france', // Use France flag for French
    subLabel: 'Français'
  },
  {
    label: 'Irish',
    value: 'ga',
    systemValue: merge(msMSCore),
    adapterLocale: msMSAdapter,
    icon: 'twemoji:flag-ireland', // Use Ireland flag for Irish
    subLabel: 'Gaeilge'
  },
  {
    label: 'Kurdish',
    value: 'ku',
    systemValue: merge(msMSCore), // Adjust as needed
    adapterLocale: msMSAdapter, // Use Kurdish adapter
    icon: 'twemoji:flag-iraq', // Use Iraq flag for Kurdish (Kurdish is spoken in multiple regions)
    subLabel: 'کوردی'
  },
  {
    label: 'Malay',
    value: 'ms',
    systemValue: merge(msMSCore), // Adjust as needed
    adapterLocale: msMSAdapter, // Use Malay adapter
    icon: 'twemoji:flag-malaysia', // Use Malaysia flag for Malay
    subLabel: 'Bahasa Melayu'
  },
  {
    label: 'Mandarin',
    value: 'mzh',
    systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
    adapterLocale: zhCNAdapter,
    icon: 'twemoji:flag-china', // Use China flag for Mandarin
    subLabel: '中文'
  },
  {
    label: 'Dutch',
    value: 'nl',
    systemValue: merge(zhCNCore), // Adjust as needed
    adapterLocale: zhCNAdapter, // Use Dutch adapter
    icon: 'fluent-emoji-flat:white-flag', // Use Netherlands flag for Dutch
    subLabel: 'Nederlands'
  },
  {
    label: 'Norwegian',
    value: 'no',
    systemValue: merge(zhCNCore), // Adjust as needed
    adapterLocale: zhCNAdapter, // Use Norwegian adapter
    icon: 'fluent-emoji-flat:white-flag', // Use Norway flag for Norwegian
    subLabel: 'Norsk'
  },
  {
    label: 'Portuguese',
    value: 'pt',
    systemValue: merge(ptBRDate, ptBRDataGrid, ptBRCore),
    adapterLocale: ptBRAdapter,
    icon: 'twemoji:flag-brazil', // Use Brazil flag for Portuguese
    subLabel: 'Português'
  },
  {
    label: 'Swedish',
    value: 'sv',
    systemValue: merge(svSECore), // Adjust as needed
    adapterLocale: svSEAdapter, // Use Swedish adapter
    icon: 'twemoji:flag-sweden', // Use Sweden flag for Swedish
    subLabel: 'Svenska'
  },
  {
    label: 'Polish',
    value: 'pl',
    systemValue: merge(plPLCore), // Adjust as needed
    adapterLocale: plPLAdapter, // Use Polish adapter
    icon: 'twemoji:flag-poland', // Use Poland flag for Polish
    subLabel: 'Polski'
  },
  {
    label: 'Russian',
    value: 'ru',
    systemValue: merge(ruRUCore), // Adjust as needed
    adapterLocale: ruRUAdapter, // Use Russian adapter
    icon: 'twemoji:flag-russia', // Use Russia flag for Russian
    subLabel: 'Русский'
  },
];

export const defaultLang = allLangs[0]; // English
