import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const specialOffersApi = createApi({
  reducerPath: "specialOffers",
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['SpecialOffer'],
  endpoints: (builder) => ({
    getAllSpecialOffers: builder.query({
      query: ({ pageno, limit, search, data }) => `/specialoffer/user?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['SpecialOffer'],
    }),

    addNewSpecialOffers: builder.mutation({
      query: (newSpecialOffers) => ({
        url: `/specialoffer`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newSpecialOffers,
      }),
      invalidatesTags: ['SpecialOffer'],
    }),

    updateSpecialOffers: builder.mutation({
      query: (data) => (
        {
          url: `specialoffer/${data.id}`,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        }
      ),
      invalidatesTags: ['SpecialOffer'],
    }),
  }),
});

export const {
  useGetAllSpecialOffersQuery,
  useAddNewSpecialOffersMutation,
  useUpdateSpecialOffersMutation,
  useGetSpecialofferBySearchQuery
} = specialOffersApi;
